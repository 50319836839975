<template>
  <!-- for sale and group buy view of product -->

  <div v-if="!category && (data?.ProductSaleStatus == 'ACTIVE' || data.GroupBuyId > 0)" class="product-list-sale">
    <div class="sale-container">
      <div class="image-container sale-image">
        <div class="prod-image-list-same-size-sale" :style="{ 'background-image': $h.getStyleImage(data.PrimaryImage, 'PRODUCT', null, true, data) }" @click="onSelectProduct(data)"></div>
      </div>
      <FlashSaleGroupComponent :data="data" />
    </div>

    <div class="group-sale-container">
      <div class="content" @click="onSelectProduct(data)">
        <h4 v-if="shop">{{ data?.Name }}</h4>
        <h4 v-else>{{ $h.getAttribute(data, $t.getLanguage(), 'Name') }}</h4>
        <span v-if="data?.IsKOLEligible" class="kol">{{ $t.getTranslation('LBL_KOL_ELIGIBLE_PRODUCT') }}</span>

        <div v-if="shop && !$configs?.hideProductPrice">
          <div v-if="data?.ProductSaleAmount && data?.ProductSaleStatus == 'ACTIVE'">
            <span>
              <h4 class="price sale-price">{{ $h.formatPrice(data?.RegularSellingPrice, data?.CurrencyCode) }}</h4>
              <h4 class="price">{{ $h.formatPrice(data?.ProductSaleAmount, data?.CurrencyCode) }}</h4>
            </span>
          </div>
          <div v-else-if="data?.DiscountPrice && data?.DiscountPrice < data?.RegularSellingPrice">
            <span v-if="data?.IsFuture == 0">
              <h4 class="price sale-price">{{ $h.formatPrice(data?.RegularSellingPrice, data?.CurrencyCode) }}</h4>
              <h4 class="price">{{ $h.formatPrice(data?.DiscountPrice, data?.CurrencyCode) }}</h4>
            </span>
          </div>
          <h4 v-else class="price">
            <span v-if="data?.IsFuture == 0">
              {{ $h.formatPrice(data?.RegularSellingPrice, data?.CurrencyCode) }}
            </span>
          </h4>
        </div>

        <div v-else>
          <div>
            <div v-if="data?.Price?.DiscountPrice && data?.Price.DiscountPrice < data?.Price?.RegularSellingPrice">
              <h4 class="price sale-price">{{ $h.formatPrice(data?.Price?.RegularSellingPrice, data?.Price?.CurrencyCode) }}</h4>
              <h4 class="price">{{ $h.formatPrice(data?.Price?.DiscountPrice, data?.Price?.CurrencyCode) }}</h4>
            </div>
            <h4 v-else class="price">{{ $h.formatPrice(data?.Price?.RegularSellingPrice, data?.Price?.CurrencyCode) }}</h4>
          </div>
        </div>

        <div v-if="data?.PackingQty && data?.PackingQty > 1"><font-awesome-icon :icon="['fal', 'wine-bottle']" fixed-width /> x {{ data.PackingQty }}</div>
        <star-rating :increment="0.1" :star-size="13" :show-rating="false" :rating="rating" :read-only="true"></star-rating>
      </div>
      <div class="sale-text">
        <p>
          {{ $t.getTranslation('LBL_START_DATE') }} <strong> {{ $h.formatDate(data.StartDate) }} </strong>
        </p>
        <p>
          {{ $t.getTranslation('LBL_END_DATE') }} <strong>{{ $h.formatDate(data.EndDate) }} </strong>
        </p>
      </div>
    </div>

    <span class="rating" @click="action(data)" v-if="type == 'cellar'">
      <font-awesome-icon :icon="['far', 'times']" />
    </span>
  </div>
  <!-- end for sale and group buy view of product -->

  <!-- for normal view of product -->
  <div class="product-list" v-else>
    <div class="prod-image-list-same-size" :style="{ 'background-image': $h.getStyleImage(data.PrimaryImage, 'PRODUCT', null, true, data) }" @click="onSelectProduct(data)"></div>
    <div class="content" @click="onSelectProduct(data)">
      <h4 v-if="shop">{{ data?.Name }}</h4>
      <h4 v-else>{{ $h.getAttribute(data, $t.getLanguage(), 'Name') }}</h4>
      <span v-if="data?.IsKOLEligible" class="kol">{{ $t.getTranslation('LBL_KOL_ELIGIBLE_PRODUCT') }}</span>

      <div v-if="shop && !$configs?.hideProductPrice">
        <div v-if="data?.ProductSaleAmount && data?.ProductSaleStatus == 'ACTIVE'">
          <span>
            <h4 class="price sale-price">{{ $h.formatPrice(data?.RegularSellingPrice, data?.CurrencyCode) }}</h4>
            <h4 class="price">{{ $h.formatPrice(data?.ProductSaleAmount, data?.CurrencyCode) }}</h4>
          </span>
        </div>
        <div v-else-if="data?.DiscountPrice && data?.DiscountPrice < data?.RegularSellingPrice">
          <span v-if="data?.IsFuture == 0">
            <h4 class="price sale-price">{{ $h.formatPrice(data?.RegularSellingPrice, data?.CurrencyCode) }}</h4>
            <h4 class="price">{{ $h.formatPrice(data?.DiscountPrice, data?.CurrencyCode) }}</h4>
          </span>
        </div>
        <h4 v-else class="price">
          <span v-if="data?.IsFuture == 0">
            {{ $h.formatPrice(data?.RegularSellingPrice, data?.CurrencyCode) }}
          </span>
        </h4>
      </div>

      <div v-else>
        <div v-if="!$configs?.hideProductPrice">
          <div v-if="data?.Price?.DiscountPrice && data?.Price.DiscountPrice < data?.Price?.RegularSellingPrice">
            <h4 class="price sale-price">{{ $h.formatPrice(data?.Price?.RegularSellingPrice, data?.Price?.CurrencyCode) }}</h4>
            <h4 class="price">{{ $h.formatPrice(data?.Price?.DiscountPrice, data?.Price?.CurrencyCode) }}</h4>
          </div>
          <h4 v-else class="price">{{ $h.formatPrice(data?.Price?.RegularSellingPrice, data?.Price?.CurrencyCode) }}</h4>
        </div>
      </div>

      <div v-if="data?.PackingQty && data?.PackingQty > 1"><font-awesome-icon :icon="['fal', 'wine-bottle']" fixed-width /> x {{ data.PackingQty }}</div>
      <star-rating :increment="0.1" :star-size="13" :show-rating="false" :rating="rating" :read-only="true"></star-rating>

      <div style="display: flex" v-if="!category && !$configs.hideProductPrice">
        <div
          v-if="data?.IsBest && data?.IsBest > 0"
          style="background-size: contain; width: 50px; height: 50px; background-repeat: no-repeat; display: flex; justify-content: center; color: #fff; align-items: center; font-size: 8px"
          :style="{ 'background-image': 'url(' + require('@/assets/images/sticker-best2.png') + ')' }"
        >
          <span style="width: 30px; text-align: center">
            {{ $t.getTranslation('LBL_ISBEST') }}
          </span>
        </div>
        <div
          v-if="data?.IsSale && data?.IsSale > 0"
          style="background-size: contain; width: 50px; height: 50px; background-repeat: no-repeat; display: flex; justify-content: center; color: #fff; align-items: center; font-size: 8px"
          :style="{ 'background-image': 'url(' + require('@/assets/images/sticker-flower.png') + ')' }"
        >
          <span>
            {{ $t.getTranslation('LBL_ISSALE') }}
          </span>
        </div>
        <div
          v-if="data?.IsNew && data?.IsNew > 0"
          style="background-size: contain; width: 50px; height: 50px; background-repeat: no-repeat; display: flex; justify-content: center; color: #fff; align-items: center; font-size: 8px"
          :style="{ 'background-image': 'url(' + require('@/assets/images/sticker-flower.png') + ')' }"
        >
          <span>
            {{ $t.getTranslation('LBL_ISNEW') }}
          </span>
        </div>
        <div
          v-if="data?.IsHot && data?.IsHot > 0"
          style="background-size: contain; width: 50px; height: 50px; background-repeat: no-repeat; display: flex; justify-content: center; color: #fff; align-items: center; font-size: 8px"
          :style="{ 'background-image': 'url(' + require('@/assets/images/sticker-flower.png') + ')' }"
        >
          <span>
            {{ $t.getTranslation('LBL_ISHOT') }}
          </span>
        </div>
      </div>
    </div>

    <span class="year">{{ data.Year }}</span>

    <span class="rating" @click="action(data)" v-if="type == 'cellar'">
      <font-awesome-icon :icon="['far', 'times']" />
    </span>
  </div>
  <!-- end for normal view of product -->
</template>

<script>
import { defineComponent, inject, ref, computed, defineAsyncComponent } from 'vue'
import { useStore } from '@/store'

import { f7 } from 'framework7-vue'

// import StarRating from '@/components/rating/star-rating.vue'
// import FlashSaleGroupComponent from '@/components/FlashSaleGroupComponent.vue'

export default defineComponent({
  name: 'ProductListCardComponent',
  components: {
    StarRating: defineAsyncComponent(() => import(/* webpackChunkName: "star-rating" */ /* webpackMode: "lazy" */ '@/components/rating/star-rating.vue')),
    FlashSaleGroupComponent: defineAsyncComponent(() => import(/* webpackChunkName: "flash-sale" */ /* webpackMode: "lazy" */ '@/components/FlashSaleGroupComponent.vue'))
  },
  props: {
    chatsend: Function,
    category: {
      type: Boolean,
      default: false
    },
    data: Object,
    type: String,
    action: Function,
    isShop: {
      type: Boolean,
      default: false
    }
  },
  emits: ['selected'],
  setup(props, { emit }) {
    const store = useStore()

    const $f7router = f7.views.get('#main-view').router

    const userToken = computed(() => store.getters['user/getData']?.Token)
    const shop = ref(false)
    const rating = ref(0)

    if (props?.data?.TotalRating <= 0) {
      rating.value = 4.5
    } else {
      rating.value = props?.data?.TotalRating
    }

    if (props?.isShop) {
      shop.value = true
    }

    const onSelectProduct = (data) => {
      if (props?.type && props?.type === 'select') {
        emit('selected', data)
        return
      }

      if (props?.chatsend) {
        props.chatsend(data.ProductKey)
        return
      }

      // if (!userToken.value) {
      //   $f7router.navigate({ name: 'loginPage' })
      //   return
      // }

      $f7router.navigate({ name: 'productViewPage', params: { code: data.ProductKey }, query: { sku: props.data.Sku } })
    }

    return { onSelectProduct, shop, rating, userToken, store }
  }
})
</script>

<style scoped>
.product-list-sale {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 0px 10px;
  border: 1px solid #f7f7f7;
  border-radius: 5px;
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 10%);
  position: relative;
  overflow: hidden;
  cursor: pointer;
  width: calc(100% - 0px);
}
.group-sale-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  padding: 0 20px;
}

.prod-image-list-same-size-sale {
  width: 150px;
  height: 150px;
  border: 1px solid #f7f7f7;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
}

.sale-text p {
  margin: 0px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.sale-text {
  margin-top: 10px;
  padding-top: 20px;
  font-size: 12px;
  border-top: 1px solid #f1f1f1;
}
.sale-image {
  background: #fff;
  border-radius: 10px;
}
.sale-container {
  position: relative;
  background-image: linear-gradient(to right, #0c4d8f, #0080ff);
  padding: 8px;
}

.price {
  font-weight: 300 !important;
}

.notif-content {
  margin-top: 10px;
}
.notification-new {
  background-color: #dea905;
  color: white;
  text-decoration: none;
  padding: 5px 10px;
  position: relative;
  display: inline;
  border-radius: 20px;
  margin-right: 10px;
  font-size: 10px;
}
.notification-sal {
  background-color: #0c4d8f;
  color: white;
  text-decoration: none;
  padding: 5px 10px;
  position: relative;
  display: inline;
  border-radius: 20px;
  margin-right: 10px;
  font-size: 10px;
}
.notification-hot {
  background-color: #c31212;
  color: white;
  text-decoration: none;
  padding: 5px 10px;
  position: relative;
  display: inline;
  border-radius: 20px;
  margin-right: 10px;
  font-size: 10px;
}

/* .notif .badge {
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 2px 2px;
  border-radius: 50%;
  background-color: red;
  color: white;
  font-size: 8px;
} */
</style>
